import React from 'react';
import styled from 'styled-components';
// import ReactImageMagnify from 'react-image-magnify';
import ReactImageZoom from 'react-image-zoom';
import Text from '../Text/Text';
import Icon from '../Icon/Icon';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import useWindowSize from '../../utils/useWindowSize';
import { BigView } from '../BigView/BigView';

const Container = styled.div`
  border-radius: 5px;
  background-color: #f5f5f5;
  padding: 10px 0;
  min-height: 300px;
  /* max-width: 1000px; */
  /* border: 1px solid #acacac; */
`;

const TinyImage = styled.div`
  width: 60px;
  height: 60px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #acacac;
  border-radius: 5px;
  margin: 20px;
  cursor: pointer;
  &:hover {
    border: 2px solid#ff6b2f;
  }
`;

const WrapBanner = styled.div`
  display: flex;
`;

function BannerZoom(props) {
  const [image, setImage] = React.useState(props.images[0].bannerImage.url);
  const handleChangeImage = imageId => {
    setImage(props.images[imageId].bannerImage.url);
  };
  const [zoomSizeBanner, setZoomSizeBanner] = React.useState(300);
  const size = useWindowSize();

  React.useEffect(() => {
    if (size.width >= 1600) {
      setZoomSizeBanner(750);
    } else {
      setZoomSizeBanner(300);
    }
  }, [size.width]);

  return (
    <BigView>
      <Container>
        <WrapBanner>
          <div>
            {props.images.map((img, i) => {
              return (
                <TinyImage
                  key={i}
                  src={img.bannerImage.url}
                  onMouseEnter={() => handleChangeImage(i)}
                />
              );
            })}
          </div>
          <div style={{ paddingTop: 20 }}>
            <ReactImageZoom
              width={500}
              heigth={300}
              zoomWidth={zoomSizeBanner}
              offset={{ vertical: 0, horizontal: 10 }}
              img={image}
              scale={1.6}
            />
          </div>
          <div style={{ paddingTop: 20, paddingLeft: 10, display: 'flex' }}>
            <Icon name={faSearchPlus} bgColor={'#868686'} />
            <div style={{ marginLeft: 5 }}></div>
            <Text content="Passe o mouse na imagem para ver os detalhes." />
          </div>
        </WrapBanner>
      </Container>
    </BigView>
  );
}

export default BannerZoom;
