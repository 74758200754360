import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import logo from '../images/primary_logo.png';
import moment from 'moment';

// Create styleshttps://fonts.googleapis.com/css2?family=Open+Sans&display=swap
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
  },
  logo: {
    width: 40,
    height: 40,
    margin: 25,
  },
  productImage: {
    margin: '0 30',
    width: 390,
    height: 210,
    borderRadius: 5,
  },
  view: {
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
    align: {
      flexDirection: 'row',
    },
    alignBox: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    alignBoxConfirmation: {
      display: 'flex',
    },
  },
  title: {
    paddingTop: 30,
    fontWeight: 700,
    fontSize: 14,
    color: '#0072b2',
  },
  subtitle: {
    paddingTop: 10,
    fontSize: 12,
    color: '#0072b2',
  },
  container: {
    margin: '10 30',
    generalInfo: {
      margin: '5 30',
      padding: 20,
      borderRadius: 5,
      backgroundColor: '#f5f5f5',
    },
    confirmationInfo: {
      margin: '10 30',
      padding: 20,
      borderRadius: 5,
      backgroundColor: '#16BAC5',
    },
  },
  space: {
    paddingTop: 5,
  },
  content: {
    fontSize: 8,
    color: '#0072b2',
    paddingTop: 5,
  },
  contentHighlight: {
    fontSize: 8,
    color: '#FF6B2F',
    paddingTop: 5,
  },
  contentTitle: {
    fontSize: 8,
    color: '#303030',
    marginBottom: 5,
    textDecoration: 'underline',
  },
  confirmationTitle: {
    fontSize: 8,
    color: '#fff',
  },
  confirmationContent: {
    fontSize: 8,
    color: '#fff',
  },
  longContent: {
    fontSize: 10,
    color: '#545454',
    paddingTop: 5,
  },
});

// Create Document Component
export default function PDFDoc(_, pageContext, shippingState) {
  const regex = /(<([^>]+)>)/gi;
  const regBold = /([*])/gi;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view.align}>
          <Image src={logo} style={styles.logo} />
          <View style={styles.viewContent}>
            <Text style={styles.title}>{pageContext.product.name}</Text>
            {/* <Text style={styles.title}>{pageContext.product.description}</Text> */}

            <Text style={styles.subtitle}>
              www.linkbox.com.br{pageContext.product.productUrl}
            </Text>
          </View>
        </View>
        <View style={{ marginLeft: 70 }}>
          <Image
            src={pageContext.banner[0].bannerImage.url}
            style={styles.productImage}
          />
        </View>
        {pageContext.product.isActiveInCampaign ? (
          <View style={styles.view.alignBoxConfirmation}>
            <View style={styles.container.confirmationInfo}>
              <Text style={styles.confirmationTitle}>
                PRAZO DE CONFIRMAÇÃO DE INTERESSE:{' '}
                {/* {pageContext.productDetail[0].confirmationDate} */}
                {moment(
                  Date.now() +
                    parseInt(pageContext.productDetail[0].confirmationDate) * -1
                ).format('DD/MM/YYYY')}
              </Text>
              <View style={styles.space} />
              <Text style={styles.confirmationContent}>
                DATA PARA CONCRETIZAÇÃO DA OPERAÇÃO:{' '}
                {pageContext.productDetail[0].operationDate}
              </Text>
              <View style={styles.space} />
              <Text style={styles.confirmationContent}>
                CHEGADA ESTIMADA DA MERCADORIA NO BRASIL:{' '}
                {pageContext.productDetail[0].arrivalDate}
              </Text>
              <View style={styles.space} />
              <Text style={styles.confirmationContent}>
                LOCAL PARA ENTREGA DA MERCADORIA:{' '}
                {pageContext.productDetail[0].local}
              </Text>
            </View>
          </View>
        ) : (
          <View style={{ marginTop: 50 }}></View>
        )}

        <View style={styles.view.alignBox}>
          <View style={styles.container.generalInfo}>
            <Text style={styles.contentTitle}>INFORMAÇÕES DO PRODUTO</Text>
            {pageContext.productDetail[0].unitPrice !== '' ? (
              <>
                <Text style={styles.content}>
                  {`PREÇO UNITÁRIO: ${pageContext.productDetail[0].unitPrice}`}
                </Text>
                <View style={styles.space} />
              </>
            ) : null}
            <Text style={styles.content}>
              {`ORIGEM: ${pageContext.productDetail[0].productOrigin}`}
            </Text>
            <View style={styles.space} />
            <Text style={styles.content}>
              {`PRODUTOR: ${pageContext.productDetail[0].producer}`}
            </Text>
            <View style={styles.space} />

            <Text style={styles.content}>
              {`CAIXA: ${pageContext.productDetail[0].productBox}`}
            </Text>
            <View style={styles.space} />

            <Text style={styles.content}>
              {`PALLET: ${pageContext.productDetail[0].productPallet}`}
            </Text>
            <View style={styles.space} />
            <Text style={styles.content}>
              {`PESO DO PALLET: ${pageContext.productDetail[0].palletWeight}`}
            </Text>
            <View style={styles.space} />
            <Text style={styles.content}>
              {`MEDIDAS DO PALLET: ${pageContext.productDetail[0].palletDimension}`}
            </Text>
            <View style={styles.space} />

            <Text style={styles.content}>
              {`CONTAINER: ${pageContext.productDetail[0].productContainer}`}
            </Text>
            <View style={styles.space} />

            {pageContext.productDetail[0].eanCode !== '' ? (
              <>
                <Text style={styles.content}>
                  {`EAN CODE: ${pageContext.productDetail[0].eanCode}`}
                </Text>
                <View style={styles.space} />
              </>
            ) : null}
            {pageContext.productDetail[0].dunCode !== '' ? (
              <>
                <Text style={styles.content}>
                  {`DUN CODE: ${pageContext.productDetail[0].dunCode}`}
                </Text>
                <View style={styles.space} />
              </>
            ) : null}
            {pageContext.productDetail[0].ncmCode !== '' ? (
              <>
                <Text style={styles.content}>
                  {`NCM CODE: ${pageContext.productDetail[0].ncmCode}`}
                </Text>
                <View style={styles.space} />
              </>
            ) : null}
          </View>
          {/* SIMULATOR */}
          {pageContext.product.isActiveInCampaign && (
            <View style={styles.container.generalInfo}>
              <Text style={styles.contentTitle}>ESTIMATIVAS DE CUSTO</Text>
              <Text style={styles.content}>
                Euro: {pageContext.simulator[0].euroCurrency}
              </Text>
              <View style={styles.space} />
              <Text style={styles.content}>
                Dólar: {pageContext.simulator[0].dollarCurrency}
              </Text>
              <View style={styles.space} />
              <Text style={styles.content}>
                Valor Aduaneiro:
                {pageContext.simulator[0].customsValue}
              </Text>
              <View style={styles.space} />
              <Text style={styles.content}>
                Impostos Importação:
                {pageContext.simulator[0].importsTaxesValue}
              </Text>
              <View style={styles.space} />
              <Text style={styles.content}>
                Custos no Porto:
                {pageContext.simulator[0].portTaxesValue}
              </Text>
              <View style={styles.space} />
              <Text style={styles.content}>
                Armazenagem e Margem:
                {pageContext.simulator[0].storageValue}
              </Text>
              <View style={styles.space} />
              <Text style={styles.contentHighlight}>
                Valor Total do Container para Distribuição:
                {pageContext.simulator[0].distributionValue}
              </Text>
              <View style={styles.space} />
            </View>
          )}
        </View>
        {pageContext.product.isActiveInCampaign && shippingState !== '' && (
          <>
            <View style={styles.container.generalInfo}>
              <Text style={styles.contentTitle}>
                ESTIMATIVAS DE ENTREGA - {shippingState}
              </Text>
              <Text style={styles.content}>
                Valor Total do Container no Estado Destino:
                {shippingState === 'SP'
                  ? pageContext.simulator[0].spTotal
                  : shippingState === 'SC'
                  ? pageContext.simulator[0].scTotal
                  : shippingState === 'PR'
                  ? pageContext.simulator[0].prTotal
                  : shippingState === 'MG'
                  ? pageContext.simulator[0].mgTotal
                  : shippingState === 'RS'
                  ? pageContext.simulator[0].rsTotal
                  : null}
              </Text>
              <Text style={styles.content}>
                Valor Total do Pallet:
                {shippingState === 'SP'
                  ? pageContext.simulator[0].spPallet
                  : shippingState === 'SC'
                  ? pageContext.simulator[0].scPallet
                  : shippingState === 'PR'
                  ? pageContext.simulator[0].prPallet
                  : shippingState === 'MG'
                  ? pageContext.simulator[0].mgPallet
                  : shippingState === 'RS'
                  ? pageContext.simulator[0].rsPallet
                  : null}
              </Text>
              <Text style={styles.content}>
                Valor Unitário:
                {shippingState === 'SP'
                  ? pageContext.simulator[0].spUnitValue
                  : shippingState === 'SC'
                  ? pageContext.simulator[0].scUnitValue
                  : shippingState === 'PR'
                  ? pageContext.simulator[0].prUnitValue
                  : shippingState === 'MG'
                  ? pageContext.simulator[0].mgUnitValue
                  : shippingState === 'RS'
                  ? pageContext.simulator[0].rsUnitValue
                  : null}
              </Text>
              <Text style={styles.content}>
                Frete de 1 Pallet:{' '}
                {shippingState === 'SP'
                  ? pageContext.simulator[0].spShippingValue
                  : shippingState === 'SC'
                  ? pageContext.simulator[0].scShippingValue
                  : shippingState === 'PR'
                  ? pageContext.simulator[0].prShippingValue
                  : shippingState === 'MG'
                  ? pageContext.simulator[0].mgShippingValue
                  : shippingState === 'RS'
                  ? pageContext.simulator[0].rsShippingValue
                  : null}
              </Text>
            </View>
          </>
        )}
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={{ paddingTop: 30 }}>
            <Text style={styles.title}>Condições Gerais</Text>
          </View>
          <Text style={styles.longContent}>
            {pageContext.productDetail[1].conditions.replace(regex, '')}
          </Text>
        </View>
        <View style={styles.container}>
          <View style={{ paddingTop: 30 }}>
            <Text style={styles.title}>Sobre o produto:</Text>
          </View>
          <Text style={styles.longContent}>
            {pageContext.productDetail[2].longProductDescription
              .replace(regex, '')
              .replace(regBold, '')}
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={{ paddingTop: 30 }}>
            <Text style={styles.title}>Sobre o fornecedor:</Text>
          </View>
          <Text style={styles.longContent}>
            {pageContext.productDetail[2].longSupplierDescription
              .replace(regex, '')
              .replace(regBold, '')}
          </Text>
        </View>
        {pageContext.productDetail[2].certifies.length > 0 && (
          <View style={styles.container}>
            <View style={{ paddingTop: 30 }}>
              <Text style={styles.title}>Certificados:</Text>
            </View>
            <View style={{ margin: 10, flexDirection: 'row' }}>
              {pageContext.productDetail[2].certifies.map(item => (
                <>
                  <Image src={item.url} style={{ width: 40, height: 40 }} />
                </>
              ))}
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
}
