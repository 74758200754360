import React from 'react';
import styled from 'styled-components';
import design from '../../config/design';
import Icon from '../Icon/Icon';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { View } from '../View/View';
import Countdown from 'react-countdown';

const StyledCountdown = styled.div`
  .distributed {
    background-color: ${design.colors.primaryGreen};
    box-sizing: border-box;
    border-radius: 5px;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    /* identical to box height */
    text-transform: uppercase;
    display: flex;

    justify-content: space-between;
    @media screen and (min-width: ${design.breakpoints.mobile}px) {
      width: 100%;
      flex-wrap: wrap;
    }
    @media screen and (min-width: ${design.breakpoints.tablet}px) {
      width: 100%;
      flex-wrap: nowrap;
    }
    @media screen and (min-width: ${design.breakpoints.desktop}px) {
      width: 100%;
      flex-wrap: nowrap;
    }
    @media screen and (min-width: ${design.breakpoints.desktopXL}px) {
      width: 100%;
      flex-wrap: nowrap;
    }

    padding: 10px 24px;
  }

  .distributed .side-left,
  .distributed .side-center,
  .distributed .side-right {
    display: inline-block;
    vertical-align: top;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    color: ${design.colors.white};
    @media screen and (min-width: ${design.breakpoints.mobile}px) {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
    }
    @media screen and (min-width: ${design.breakpoints.tablet}px) {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .distributed .side-center {
    display: flex;
  }

  .distributed .side-right {
    @media screen and (min-width: ${design.breakpoints.mobile}px) {
      width: 100%;
      margin-top: 10px;
    }
  }

  .container-right {
    @media screen and (min-width: ${design.breakpoints.tablet}px) {
      display: flex;
      justify-content: flex-end;
    }
  }

  .distributed .side-icons {
    padding-bottom: 10px;
  }

  .distributed h3 {
    color: ${design.colors.white};
    font-weight: bold;
    @media screen and (min-width: ${design.breakpoints.mobile}px) {
      font-size: 13px;
      line-height: 14px;
    }
    @media screen and (min-width: ${design.breakpoints.tablet}px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .distributed h5 {
    color: ${design.colors.white};
    font-weight: bold;
    @media screen and (min-width: ${design.breakpoints.mobile}px) {
      font-size: 12px;
      line-height: 14px;
    }
    @media screen and (min-width: ${design.breakpoints.tablet}px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

const CountdownBanner = props => {
  return (
    <StyledCountdown>
      <div className="distributed">
        <div>
          <View top={1} />
          <div className="side-center">
            <Icon name={faExclamationCircle} bgColor="white" big />
            <View left={1} />
            <div>
              <h3>Prazo de confirmação de interesse:</h3>
              {/* <h5>
                {moment(Date.now() + parseInt(props.stopwatch) * -1).format(
                  'DD/MM/YYYY'
                )}
              </h5> */}
              <Countdown
                date={Date.now() + parseInt(props.stopwatch) * -1}
                renderer={props => (
                  <h5>
                    {props.days} Dias, {props.hours} Horas, {props.minutes}{' '}
                    minutos e {props.seconds} segundos
                  </h5>
                  // <h5>
                  //   {props.days} Dias, {props.hours} Horas, {props.minutes}{' '}
                  //   minutos e {props.seconds} segundos
                  // </h5>
                )}
              />
            </div>
          </div>
        </div>
        <View mobileTop={4} tabletTop={2} />
        <div className="container-right">
          <div className="side-right">
            <div className="side-icons">
              <p>
                {' '}
                DATA PARA CONCRETIZAÇÃO DA OPERAÇÃO:{' '}
                <strong>{props.dateOperation}</strong>
              </p>
            </div>
            <div className="side-icons">
              <p>
                CHEGADA ESTIMADA DA MERCADORIA NO BRASIL:{' '}
                <strong>{props.arrivalProductsinBrazil}</strong>
              </p>
            </div>
            <div className="side-icons">
              <p>
                LOCAL PARA ENTREGA DA MERCADORIA:{' '}
                <strong>{props.placeArrival}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </StyledCountdown>
  );
};
export default CountdownBanner;
