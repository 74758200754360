import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Visible } from '../Visible/Visible';
import YouTube from 'react-youtube';
import { View } from '../View/View';
import design from '../../config/design';

import ReactMarkdownMain, * as ReactMarkdownAlt from 'react-markdown/with-html';

const ReactMarkdown = ReactMarkdownMain || ReactMarkdownAlt;

const BgModal = styled.div`
  background-color: #00000055;
  width: 100%;
  height: 100%;
  z-index: 10;
  position: fixed;
  display: ${props => (props.close ? 'none' : 'block')};

  h2 {
    @media screen and (min-width: ${design.breakpoints.mobile}px) {
      padding-top: 3rem;
    }
    @media screen and (min-width: ${design.breakpoints.tablet}px) {
      padding-top: 3rem;
      padding-left: 30rem;
    }
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }

  h4 {
    padding-top: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    @media screen and (min-width: ${design.breakpoints.tablet}px) {
      padding-left: 10rem;
      padding-right: 10rem;
    }
    display: flex;
    justify-content: center;
    color: #fff;
  }
`;

/* const _onReady = e => {
  e.target.playVideo(true);
}; */

function VideoModal(props) {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setShowContent(true);
    }, 1500);
  }, []);

  return (
    <>
      {showContent ? (
        <BgModal close={props.close}>
          <>
            <h4>
              <ReactMarkdown source={props.modalMessage} escapeHtml={false} />
            </h4>
            <h2 onClick={props.closeDropdown}>Fechar</h2>
            <View center>
              <Visible mobile tablet>
                <YouTube
                  videoId={!props.close ? props.url : null}
                  opts={{ height: '193', width: '100%' }}
                  onReady={event => event.target.pauseVideo()}
                />
              </Visible>
              <Visible desktop>
                <YouTube
                  videoId={!props.close ? props.url : null}
                  onReady={event => event.target.pauseVideo()}
                />
              </Visible>
            </View>
          </>
        </BgModal>
      ) : null}
    </>
  );
}

export default VideoModal;
