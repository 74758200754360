import React from 'react';
import styled from 'styled-components';
import design from '../../config/design';
import { View } from '../View/View';
import { Spaced } from '../Container/Container';

const SimulatorContainer = styled.div`
  background-color: #f5f5f5;
  margin-top: 1rem;

  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    width: 300px;
  }
  @media screen and (min-width: 375px) {
    width: 350px;
  }
  @media screen and (min-width: 414px) {
    width: 390px;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    width: 350px;
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    width: 445px;
  }
  padding: 20px;
  border-radius: 5px;
`;

const VerticalLine = styled.div`
  border-right: 2px solid #d9d9d9;
`;

const Title = styled.p`
  color: #0072b2;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  letter-spacing: 2px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
`;

const ContentText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: ${props => (props.bold ? 'bold' : 'regular')};
  text-align: left;
  color: ${props => props.color};
`;

const LineGray = styled.div`
  border: 0.8px solid #d9d9d9;
  width: 100%;
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
`;

function Simulator(props) {
  // const [shippingValueTotal, setShippingValueTotal] = React.useState('R$ 0,00');
  // const [shippingValuePallet, setShippingValuePallet] = React.useState(
  //   'R$ 0,00'
  // );
  // const [shippingValueUnit, setShippingValueUnit] = React.useState('R$ 0,00');
  // const [shippingValue, setShippingValue] = React.useState('R$ 0,00');

  // const handleChange = selectedValue => {
  //   switch (selectedValue.target.value) {
  //     case '':
  //       sessionStorage.setItem('@state', '');
  //       setShippingValueTotal('R$ 0,00');
  //       setShippingValuePallet('R$ 0,00');
  //       setShippingValueUnit('R$ 0,00');
  //       setShippingValue('R$ 0,00');
  //       break;
  //     case 'SC':
  //       if (props.content.length > 0) {
  //         sessionStorage.setItem('@state', 'SC');
  //         setShippingValueTotal(props.content[0].scTotal);
  //         setShippingValuePallet(props.content[0].scPallet);
  //         setShippingValueUnit(props.content[0].scUnitValue);
  //         setShippingValue(props.content[0].scShippingValue);
  //       }
  //       break;
  //     case 'SP':
  //       if (props.content.length > 0) {
  //         sessionStorage.setItem('@state', 'SP');
  //         setShippingValueTotal(props.content[0].spTotal);
  //         setShippingValuePallet(props.content[0].spPallet);
  //         setShippingValueUnit(props.content[0].spUnitValue);
  //         setShippingValue(props.content[0].spShippingValue);
  //       }
  //       break;
  //     case 'MG':
  //       if (props.content.length > 0) {
  //         sessionStorage.setItem('@state', 'MG');
  //         setShippingValueTotal(props.content[0].mgTotal);
  //         setShippingValuePallet(props.content[0].mgPallet);
  //         setShippingValueUnit(props.content[0].mgUnitValue);
  //         setShippingValue(props.content[0].mgShippingValue);
  //       }
  //       break;
  //     case 'PR':
  //       if (props.content.length > 0) {
  //         sessionStorage.setItem('@state', 'PR');
  //         setShippingValueTotal(props.content[0].prTotal);
  //         setShippingValuePallet(props.content[0].prPallet);
  //         setShippingValueUnit(props.content[0].prUnitValue);
  //         setShippingValue(props.content[0].prShippingValue);
  //       }
  //       break;
  //     case 'RS':
  //       if (props.content.length > 0) {
  //         sessionStorage.setItem('@state', 'RS');
  //         setShippingValueTotal(props.content[0].rsTotal);
  //         setShippingValuePallet(props.content[0].rsPallet);
  //         setShippingValueUnit(props.content[0].rsUnitValue);
  //         setShippingValue(props.content[0].rsShippingValue);
  //       }
  //       break;

  //     default:
  //       break;
  //   }
  // };
  return (
    <>
      <Title>SIMULADOR DE PREÇO</Title>
      <View top={1} />
      <SimulatorContainer>
        <Title>ESTIMATIVAS DE CUSTO</Title>
        <View top={1} />
        <Spaced>
          <ContentText color="#0072B2">Euro</ContentText>
          <ContentText color="#0072B2" bold>
            {props.content.length > 0
              ? props.content[0].euroCurrency
              : '€ 0,00'}
          </ContentText>
          <VerticalLine />
          <ContentText color="#0072B2">Dólar</ContentText>
          <ContentText color="#0072B2" bold>
            {props.content.length > 0
              ? props.content[0].dollarCurrency
              : '$ 0,00'}
          </ContentText>
        </Spaced>
        <View top={3} />
        <Spaced>
          <ContentText color="#0072B2">Valor Aduaneiro:</ContentText>
          <ContentText color="#0072B2" bold>
            {props.content.length > 0
              ? props.content[0].customsValue
              : 'R$ 0,00'}
          </ContentText>
        </Spaced>
        <View top={1} />
        <LineGray />
        <View top={1} />
        <Spaced>
          <ContentText color="#0072B2">Impostos Importação:</ContentText>
          <ContentText color="#0072B2" bold>
            {props.content.length > 0
              ? props.content[0].importsTaxesValue
              : 'R$ 0,00'}
          </ContentText>
        </Spaced>
        <View top={1} />
        <LineGray />
        <View top={1} />
        <Spaced>
          <ContentText color="#0072B2">Custos no Porto:</ContentText>
          <ContentText color="#0072B2" bold>
            {props.content.length > 0
              ? props.content[0].portTaxesValue
              : 'R$ 0,00'}
          </ContentText>
        </Spaced>
        <View top={1} />
        <LineGray />
        <View top={1} />
        <Spaced>
          <ContentText color="#0072B2">Armazenagem e Margem:</ContentText>
          <ContentText color="#0072B2" bold>
            {props.content.length > 0
              ? props.content[0].storageValue
              : 'R$ 0,00'}
          </ContentText>
        </Spaced>
        <View top={1} />
        <LineGray />
        <View top={1} />
        <Spaced>
          <ContentText color="#FF6B2F" bold>
            Valor Total do Container para Distribuição:
          </ContentText>
          <ContentText color="#FF6B2F" bold>
            {props.content.length > 0
              ? props.content[0].distributionValue
              : 'R$ 0,00'}
          </ContentText>
        </Spaced>
        <View top={1} />
      </SimulatorContainer>
      {/*  Delivery */}
      {props.content.length > 0 && props.content[0].showStatePrice ? (
        <>
          <SimulatorContainer>
            <Title>ESTIMATIVAS DE ENTREGAS</Title>
            <View top={1} />
            <Select onChange={props.handleChange}>
              <option value="">Selecione um estado:</option>
              <option value="SC">SC</option>
              <option value="SP">SP</option>
              <option value="PR">PR</option>
              <option value="MG">MG</option>
              <option value="RS">RS</option>
            </Select>
            <View top={1} />
            <Spaced>
              <ContentText color="#0072B2" bold>
                Valor Total do Container no Estado Destino:
              </ContentText>
              <ContentText color="#0072B2" bold>
                {props.shippingValueTotal}
              </ContentText>
            </Spaced>
            <View top={1} />
            <LineGray />
            <View top={1} />
            <Spaced>
              <ContentText color="#0072B2" bold>
                Valor Total do Pallet:
              </ContentText>
              <ContentText color="#0072B2" bold>
                {props.shippingValuePallet}
              </ContentText>
            </Spaced>
            <View top={1} />
            <LineGray />
            <View top={1} />
            <Spaced>
              <ContentText color="#0072B2" bold>
                Valor Unitário:
              </ContentText>
              <ContentText color="#0072B2" bold>
                {props.shippingValueUnit}
              </ContentText>
            </Spaced>
            <View top={1} />

            <LineGray />
            <View top={1} />
            <Spaced>
              <ContentText color="#0072B2" bold>
                Frete de 1 Pallet:
              </ContentText>
              <ContentText color="#0072B2" bold>
                {props.shippingValue}
              </ContentText>
            </Spaced>
            <View top={1} />
          </SimulatorContainer>
        </>
      ) : null}
      <View bottom={3} />
    </>
  );
}

export default Simulator;
