import styled from 'styled-components';
import design from '../../config/design';

export const GridSimulator = styled.div`
  display: grid;
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    grid-template-columns: auto;
    justify-content: flex-start;
    grid-gap: 10px;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    grid-template-columns: auto;
    justify-content: flex-start;
    grid-gap: 50px;
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    grid-template-columns: auto auto;
    justify-content: space-between;
    grid-gap: 0px;
  }
  @media screen and (min-width: ${design.breakpoints.desktopXL}px) {
    grid-template-columns: auto auto;
    justify-content: auto;
    grid-gap: 0px;
  }
`;
