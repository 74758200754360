import React from 'react';
import design from '../config/design';
import { graphql } from 'gatsby';

import LayoutWrapper from '../components/LayoutWrapper/LayoutWrapper';
import Carousel from '../components/Carousel/Carousel';
import Title from '../components/Title/Title';
import Text from '../components/Text/Text';
import CountdownBanner from '../components/CountdownBanner/CountdownBanner';
import { View } from '../components/View/View';
import {
  faMapMarkerAlt,
  faTractor,
  faBox,
  faPallet,
  faBoxes,
  faQrcode,
  faBarcode,
  faDollarSign,
  faWeightHanging,
  faRuler,
  faReceipt,
} from '@fortawesome/free-solid-svg-icons';
import { Flex, FlexWrap } from '../components/Container/Container';
import Icon from '../components/Icon/Icon';
import { Visible } from '../components/Visible/Visible';
import YouTube from 'react-youtube';
import Button from '../components/Button/Button';
import VideoModal from '../components/VideoModal/VideoModal';
import { GridSimulator } from '../components/GridSimulator/GridSimulator';
import Simulator from '../components/Simulator/Simulator';

import PDFDoc from '../utils/generatePdf';

import { PDFDownloadLink } from '@react-pdf/renderer';
import styled from 'styled-components';
import { BigView } from '../components/BigView/BigView';
import BannerZoom from '../components/BannerZoom/BannerZoom';

const TextDescription = styled.p`
  color: ${props => props.color};
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  margin: -3px 0;
`;

export default function ProductPages({ data, pageContext }) {
  const [dropdown, setDropdown] = React.useState(false);

  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  const contentText = data.productsConfig.nodes[0];

  const [shippingValueTotal, setShippingValueTotal] = React.useState('R$ 0,00');
  const [shippingValuePallet, setShippingValuePallet] = React.useState(
    'R$ 0,00'
  );
  const [shippingState, setShippingState] = React.useState('');
  const [shippingValueUnit, setShippingValueUnit] = React.useState('R$ 0,00');
  const [shippingValue, setShippingValue] = React.useState('R$ 0,00');

  const handleChange = selectedValue => {
    setShippingState(selectedValue.target.value);
    switch (selectedValue.target.value) {
      case '':
        setShippingValueTotal('R$ 0,00');
        setShippingValuePallet('R$ 0,00');
        setShippingValueUnit('R$ 0,00');
        setShippingValue('R$ 0,00');
        break;
      case 'SC':
        if (pageContext.simulator.length > 0) {
          setShippingValueTotal(pageContext.simulator[0].scTotal);
          setShippingValuePallet(pageContext.simulator[0].scPallet);
          setShippingValueUnit(pageContext.simulator[0].scUnitValue);
          setShippingValue(pageContext.simulator[0].scShippingValue);
        }
        break;
      case 'SP':
        if (pageContext.simulator.length > 0) {
          setShippingValueTotal(pageContext.simulator[0].spTotal);
          setShippingValuePallet(pageContext.simulator[0].spPallet);
          setShippingValueUnit(pageContext.simulator[0].spUnitValue);
          setShippingValue(pageContext.simulator[0].spShippingValue);
        }
        break;
      case 'MG':
        if (pageContext.simulator.length > 0) {
          setShippingValueTotal(pageContext.simulator[0].mgTotal);
          setShippingValuePallet(pageContext.simulator[0].mgPallet);
          setShippingValueUnit(pageContext.simulator[0].mgUnitValue);
          setShippingValue(pageContext.simulator[0].mgShippingValue);
        }
        break;
      case 'PR':
        if (pageContext.simulator.length > 0) {
          setShippingValueTotal(pageContext.simulator[0].prTotal);
          setShippingValuePallet(pageContext.simulator[0].prPallet);
          setShippingValueUnit(pageContext.simulator[0].prUnitValue);
          setShippingValue(pageContext.simulator[0].prShippingValue);
        }
        break;
      case 'RS':
        if (pageContext.simulator.length > 0) {
          setShippingValueTotal(pageContext.simulator[0].rsTotal);
          setShippingValuePallet(pageContext.simulator[0].rsPallet);
          setShippingValueUnit(pageContext.simulator[0].rsUnitValue);
          setShippingValue(pageContext.simulator[0].rsShippingValue);
        }
        break;

      default:
        break;
    }
  };

  const closeDropdown = () => {
    setDropdown(true);
    document.body.removeEventListener('click', closeDropdown);
  };

  React.useEffect(() => {
    document.body.addEventListener('click', closeDropdown);
  }, [closeDropdown]);
  return (
    <>
      {pageContext.product.isActiveInCampaign ? (
        <>
          <VideoModal
            close={dropdown}
            closeButton={closeDropdown}
            url={contentText.campaignVideoId}
            modalMessage={contentText.textPopUp}
          />
        </>
      ) : null}
      <LayoutWrapper>
        <View
          top={2}
          desktopLeft={6}
          desktopRight={6}
          mobileLeft={1}
          mobileRight={1}
          tabletLeft={3}
          tabletRight={3}
          bottom={2}
        >
          <View top={3.5} bottom={3.5}>
            <Visible desktop>
              <BannerZoom images={pageContext.banner} />
            </Visible>
            <Visible mobile>
              <Carousel content={pageContext.banner} disableLink />
            </Visible>
          </View>
          <div
            style={{
              display: pageContext.product.isActiveInCampaign
                ? 'flex'
                : 'block',
              justifyContent: 'center',
            }}
          >
            <BigView>
              <Title
                color={design.colors.blue150}
                content={pageContext.product.name}
              />
              {!pageContext.product.isActiveInCampaign ? (
              <>
                <View top={0.5} />
                <Text
                  color={design.colors.dark}
                  content={pageContext.product.description}
                />
              </>
              ): null}
              
            </BigView>
          </div>
          {pageContext.product.isActiveInCampaign ? (
            <>
              <BigView>
                <View top={0.5} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    color={design.colors.dark}
                    content={pageContext.product.description}
                  />
                </div>
                <View top={3}>
                  <CountdownBanner
                    stopwatch={pageContext.productDetail[0].confirmationDate}
                    dateOperation={pageContext.productDetail[0].operationDate}
                    arrivalProductsinBrazil={
                      pageContext.productDetail[0].arrivalDate
                    }
                    placeArrival={pageContext.productDetail[0].local}
                  />
                </View>
              </BigView>
            </>
          ) : null}

          {/* Product Infos */}
          <BigView>
            <GridSimulator>
              <View top={2} bottom={3}>
                {pageContext.productDetail[0].unitPrice !== '' ? (
                  <>
                    <Flex>
                      <Icon
                        name={faDollarSign}
                        bgColor={design.colors.primaryBlue}
                      />
                      <View left={0.5} />
                      <Text
                        color={design.colors.primaryBlue}
                        content={`PREÇO UNITÁRIO: <b>${pageContext.productDetail[0].unitPrice}</b>`}
                      />
                    </Flex>
                  </>
                ) : null}
                <View top={2} />
                <Flex>
                  <Icon
                    name={faMapMarkerAlt}
                    bgColor={design.colors.primaryBlue}
                  />
                  <View left={0.5} />
                  <Text
                    color={design.colors.primaryBlue}
                    content={`ORIGEM: <b>${pageContext.productDetail[0].productOrigin}</b>`}
                  />
                </Flex>
                <View top={2} />
                <Flex>
                  <Icon name={faTractor} bgColor={design.colors.primaryBlue} />
                  <View left={0.5} />
                  <Text
                    color={design.colors.primaryBlue}
                    content={`PRODUTOR: <b>${pageContext.productDetail[0].producer}</b>`}
                  />
                </Flex>
                <View top={2} />
                <Flex>
                  <Icon name={faBox} bgColor={design.colors.primaryBlue} />
                  <View left={0.5} />
                  <Text
                    color={design.colors.primaryBlue}
                    content={`CAIXA: <b>${pageContext.productDetail[0].productBox}</b>`}
                  />
                </Flex>
                <View top={2} />
                <Flex>
                  <Icon name={faPallet} bgColor={design.colors.primaryBlue} />
                  <View left={0.5} />
                  <Text
                    color={design.colors.primaryBlue}
                    content={`PALLET: <b>${pageContext.productDetail[0].productPallet}</b>`}
                  />
                </Flex>
                <View top={2} />
                <Flex>
                  <Icon
                    name={faWeightHanging}
                    bgColor={design.colors.primaryBlue}
                  />
                  <View left={0.5} />
                  <Text
                    color={design.colors.primaryBlue}
                    content={`PESO DO PALLET: <b>${pageContext.productDetail[0].palletWeight}</b>`}
                  />
                  <View left={0.5} />
                  <TextDescription
                    color={design.colors.primaryBlue}
                  ></TextDescription>
                </Flex>
                <View top={2} />
                <Flex>
                  <Icon name={faRuler} bgColor={design.colors.primaryBlue} />
                  <View left={0.5} />
                  <Text
                    color={design.colors.primaryBlue}
                    content={`MEDIDAS DO PALLET: <b>${pageContext.productDetail[0].palletDimension}</b>`}
                  />
                </Flex>
                <View top={2} />
                <Flex>
                  <Icon name={faBoxes} bgColor={design.colors.primaryBlue} />
                  <View left={0.5} />
                  <Text
                    color={design.colors.primaryBlue}
                    content={`CONTAINER: <b>${pageContext.productDetail[0].productContainer}</b>`}
                  />
                </Flex>
                <View top={2} />
                {pageContext.productDetail[0].eanCode !== '' ? (
                  <>
                    <Flex>
                      <Icon
                        name={faQrcode}
                        bgColor={design.colors.primaryBlue}
                      />
                      <View left={0.5} />
                      <Text
                        color={design.colors.primaryBlue}
                        content={`EAN CODE: <b>${pageContext.productDetail[0].eanCode}</b>`}
                      />
                    </Flex>
                  </>
                ) : null}
                {pageContext.productDetail[0].dunCode !== '' ? (
                  <>
                    <View top={2} />
                    <Flex>
                      <Icon
                        name={faBarcode}
                        bgColor={design.colors.primaryBlue}
                      />
                      <View left={0.5} />
                      <Text
                        color={design.colors.primaryBlue}
                        content={`DUN CODE: <b>${pageContext.productDetail[0].dunCode}</b>`}
                      />
                    </Flex>
                  </>
                ) : null}
                <View top={2} />
                {pageContext.productDetail[0].ncmCode !== '' ? (
                  <>
                    <Flex>
                      <Icon
                        name={faReceipt}
                        bgColor={design.colors.primaryBlue}
                      />
                      <View left={0.5} />
                      <Text
                        color={design.colors.primaryBlue}
                        content={`NCM: <b>${pageContext.productDetail[0].ncmCode}</b>`}
                      />
                    </Flex>
                  </>
                ) : null}

                {pageContext.product.isActiveInCampaign ? (
                  <>
                    <View top={3} />
                    <Button
                      content={pageContext.productDetail[0].campaignButton}
                      href={pageContext.productDetail[0].campaignButtonUrl}
                      bgColor="primaryOrange"
                      width="block"
                    />
                  </>
                ) : null}
                <View top={3} />
                {isClient && (
                  <PDFDownloadLink
                    style={{ textDecoration: 'none' }}
                    fileName={`${pageContext.product.name}.pdf`}
                    document={PDFDoc(data, pageContext, shippingState)}
                  >
                    <Button
                      content={'Baixar PDF'}
                      bgColor="primaryOrange"
                      width="block"
                    />
                  </PDFDownloadLink>
                )}
              </View>
              {pageContext.product.isActiveInCampaign ? (
                <>
                  <View top={2}>
                    <Simulator
                      content={pageContext.simulator}
                      shippingValueTotal={shippingValueTotal}
                      shippingValuePallet={shippingValuePallet}
                      shippingValueUnit={shippingValueUnit}
                      shippingValue={shippingValue}
                      handleChange={handleChange}
                    />
                  </View>
                </>
              ) : null}
            </GridSimulator>
          </BigView>
          <BigView>
            <View top={2} bottom={3}>
              {pageContext.product.isActiveInCampaign ? (
                <>
                  <Title
                    color={design.colors.primaryBlue}
                    content={pageContext.productDetail[1].titleProductDetail}
                  />
                  <View top={2} />
                </>
              ) : null}

              <Visible mobile tablet>
                <FlexWrap>
                  {pageContext.productDetail[1].producerVideoId !== '' ? (
                    <>
                      <YouTube
                        videoId={pageContext.productDetail[1].producerVideoId}
                        opts={{ height: '300', width: '100%' }}
                        onReady={event => event.target.pauseVideo()}
                      />
                      <View left={1} top={1} />
                    </>
                  ) : null}
                  {pageContext.productDetail[1].productVideoId !== '' ? (
                    <>
                      <YouTube
                        videoId={pageContext.productDetail[1].productVideoId}
                        opts={{ height: '300', width: '100%' }}
                        onReady={event => event.target.pauseVideo()}
                      />
                    </>
                  ) : null}
                </FlexWrap>
              </Visible>
              <Visible desktop>
                <FlexWrap>
                  {pageContext.productDetail[1].producerVideoId !== '' ? (
                    <>
                      <YouTube
                        videoId={pageContext.productDetail[1].producerVideoId}
                        opts={{ height: '260', width: '450' }}
                        onReady={event => event.target.pauseVideo()}
                      />
                      <View left={1} />
                    </>
                  ) : null}
                  {pageContext.productDetail[1].productVideoId !== '' ? (
                    <>
                      <YouTube
                        videoId={pageContext.productDetail[1].productVideoId}
                        opts={{ height: '260', width: '450' }}
                        onReady={event => event.target.pauseVideo()}
                      />
                    </>
                  ) : null}
                </FlexWrap>
              </Visible>
            </View>
          </BigView>
          <BigView>
            {pageContext.product.isActiveInCampaign ? (
              <>
                <Title
                  color={design.colors.primaryBlue}
                  content={'Condições Gerais'}
                />
                <View top={1} />
                <Text
                  color={design.colors.dark}
                  content={pageContext.productDetail[1].conditions}
                />
                {/*  */}
                <View top={5} />
                <Title
                  color={design.colors.primaryBlue}
                  content={pageContext.productDetail[2].titleAboutProduct}
                />
                <View top={1} desktopRight={10}>
                  <Text
                    color={design.colors.dark}
                    content={
                      pageContext.productDetail[2].longProductDescription
                    }
                  />
                </View>
                <View top={5} />
                <Title
                  color={design.colors.primaryBlue}
                  content={pageContext.productDetail[2].titleAboutSupplier}
                />
                <View top={1} desktopRight={10}>
                  <Text
                    color={design.colors.dark}
                    content={
                      pageContext.productDetail[2].longSupplierDescription
                    }
                  />
                </View>
                {/* Show certifies */}
                {pageContext.productDetail[2].titleCertifies !== '' ? (
                  <>
                    <View top={5} />
                    <Title
                      color={design.colors.primaryBlue}
                      content={pageContext.productDetail[2].titleCertifies}
                    />
                    <View top={1} />
                    <FlexWrap>
                      {pageContext.productDetail[2].certifies.map(item => (
                        <>
                          <img src={item.url} width="100" height="100" />
                          <View left={1} right={1} bottom={1} />
                        </>
                      ))}
                    </FlexWrap>
                  </>
                ) : null}
                <View top={3} center />
                <Button
                  content={pageContext.productDetail[0].campaignButton}
                  href={pageContext.productDetail[0].campaignButtonUrl}
                  bgColor="primaryOrange"
                  width="block"
                />
              </>
            ) : (
              <>
                {/* Without Campaign */}
                <Title
                  color={design.colors.primaryBlue}
                  content={pageContext.productDetail[2].titleAboutProduct}
                />
                <View top={1} desktopRight={10}>
                  <Text
                    color={design.colors.dark}
                    content={
                      pageContext.productDetail[2].longProductDescription
                    }
                  />
                </View>
                <View top={5} />
                <Title
                  color={design.colors.primaryBlue}
                  content={pageContext.productDetail[2].titleAboutSupplier}
                />
                <View top={1} desktopRight={10}>
                  <Text
                    color={design.colors.dark}
                    content={
                      pageContext.productDetail[2].longSupplierDescription
                    }
                  />
                </View>
                {pageContext.productDetail[2].titleCertifies !== '' ? (
                  <>
                    <View top={5} />
                    <Title
                      color={design.colors.primaryBlue}
                      content={pageContext.productDetail[2].titleCertifies}
                    />
                    <View top={1} />
                    <FlexWrap>
                      {pageContext.productDetail[2].certifies.map(item => (
                        <>
                          <img src={item.url} width="100" height="auto" />
                          <View left={1} right={1} bottom={1} />
                        </>
                      ))}
                    </FlexWrap>
                  </>
                ) : null}

                <Visible mobile>
                  <View top={2} left={1} right={1}>
                    <Button
                      content={pageContext.productDetail[2].buttonText}
                      href={pageContext.productDetail[2].buttonUrl}
                      bgColor="primaryOrange"
                      type="rounded"
                      width="block"
                    />
                  </View>
                </Visible>
                <Visible desktop>
                  <View top={3} center>
                    <Button
                      content={pageContext.productDetail[2].buttonText}
                      href={pageContext.productDetail[2].buttonUrl}
                      bgColor="primaryOrange"
                      type="rounded"
                      width="block"
                    />
                  </View>
                </Visible>
              </>
            )}
          </BigView>
        </View>
      </LayoutWrapper>
    </>
  );
}

export const { productsConfig } = graphql`
  query {
    productsConfig: allDatoCmsProduct(
      filter: { isActiveInCampaign: { eq: true } }
    ) {
      nodes {
        campaignVideoId
        textPopUp
      }
    }
  }
`;
